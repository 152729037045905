<template>
    <GuidesStep
        :stepHeader="$t(step.title, { link: step.link === 'changePasswordLink' ? changePasswordLink : pricingLink })"
        :stepNumber="step.id" :hideIcon="true">
        <p class="gray-text">{{ $t(step.content) }}</p>
        <div v-if="step.imgName" class="img-container">
            <img class="step-img" :src="setImage" :alt="step.imgName" />
        </div>
    </GuidesStep>
</template>

<script>
import { mapGetters } from "vuex"

export default {
    props: {
        step: Object,
        link: String
    },
    components: {
        GuidesStep: () => import('@/components/GuidesStep.vue')
    },
    computed: {
        ...mapGetters(['brandConfig']),
        setImage() {
            return require(`../components/assets/step-images/${this.step.imgName}.png`)
        },
        pricingLink() {
            return `${this.brandConfig.portalLink}/Subscriptions`
        },
        changePasswordLink() {
            return `${this.brandConfig.portalLink}/ChangePassword`
        }
    }
};
</script>

<style scoped>
h1,
p,
button,
input,
label,
span,
li {
    font-family: 'Wix Madefor Display', sans-serif;
    color: white;
    margin: 0;
}

.img-container {
    display: flex;
    justify-content: center;
    background-color: #141417;
    border-radius: 8px;
    margin-top: 16px;
    padding-top: 20px;
}

.gray-text {
    font-size: 14px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.6);
}

.step-img {
    max-width: 80%;
    height: auto;
}

@media only screen and (max-width: 870px) {
    .step-img {
        width: 100%;
    }

    .img-container {
        padding: 30px 30px 0;
    }
}
</style>
